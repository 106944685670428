import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {AutoLogin, Logout, Login, SetLoginState} from '../actions/authentication.actions';
import {AuthenticationService} from '../../services/authentication.service';

interface IAuthenticationState {
  isLoggedIn: boolean;
  loginError: boolean;
}

const defaults: IAuthenticationState = {
  isLoggedIn: false,
  loginError: false
};

@State<IAuthenticationState>({
  name: 'authentication',
  defaults
})

@Injectable()
export class AuthenticationState {

  @Selector()
  static isLoggedIn(state: IAuthenticationState): boolean {
    return state.isLoggedIn;
  }

  @Selector()
  static loginError(state: IAuthenticationState): boolean {
    return state.loginError;
  }

  constructor(
    private readonly _authenticationService: AuthenticationService
  ) {
  }

  @Action(Login)
  Login(ctx: StateContext<IAuthenticationState>, action: Login): void {
    this._authenticationService.login(action.email, action.password).subscribe(data => {
      ctx.patchState({
        isLoggedIn: true,
        loginError: false
      });
    }, error => {
      ctx.patchState({
        isLoggedIn: false,
        loginError: true
      });
    });
  }

  @Action(SetLoginState)
  SetLoginState(ctx: StateContext<IAuthenticationState>, action: SetLoginState): void {
    ctx.patchState({
      isLoggedIn: action.isLogged,
    });
  }

  @Action(AutoLogin)
  AutoLogin(ctx: StateContext<IAuthenticationState>, action: AutoLogin): void {
    this._authenticationService.autologin();
  }

  @Action(Logout)
  Logout(ctx: StateContext<IAuthenticationState>, action: Logout): void {
    ctx.patchState({
      isLoggedIn: false,
    });
    this._authenticationService.logout();
  }
}
