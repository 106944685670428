export class Login {
  static readonly type = '[authentication] Login';

  constructor(public email: string, public password: string) {
  }
}

export class SetLoginState {
  static readonly type = '[authentication] SetLoginState';

  constructor(public isLogged: boolean) {
  }
}

export class AutoLogin {
  static readonly type = '[authentication] autologin';

  constructor() {
  }
}

export class Logout {
  static readonly type = '[authentication] logout';

  constructor() {
  }
}
