import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {exhaustMap, take} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthenticationState} from '../store/states/authentication.state';
import {TokenService} from '../services/token.service';

@Injectable({
  providedIn: 'platform'
})
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store, private tokenService: TokenService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(AuthenticationState.isLoggedIn).pipe(take(1), exhaustMap(role => {
      if (role) {
        const reqNew = req.clone({
          headers: req.headers.set('x-access-token', `${this.tokenService.getToken()}`)
        });

        return next.handle(reqNew);

      } else {
        return next.handle(req);
      }
    }));
  }
}
