import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

export const TOKEN = 'token';
export const REFRESH_TOKEN = 'refToken';

@Injectable()
export class TokenService {
  constructor() {
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN, token);
  }

  getToken(): string | null {
    return localStorage.getItem(TOKEN);
  }

  removeToken(): void {
    localStorage.removeItem(TOKEN);
  }

  getAuthTokenData(token: string): { _id: string, name: string, email: string, expireDate: Date | null } {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    return {
      _id: decodedToken.data._id,
      name: decodedToken.data.name,
      email: decodedToken.data.email,
      expireDate: new Date(decodedToken.iat * 1000 + 3600)
    };
  }

  getResetPasswordTokenData(token: string): { iat: number, expireDate: Date | null } | null {
    const helper = new JwtHelperService();

    try {
      const decodedToken = helper.decodeToken(token);

      return {
        iat: decodedToken.iat,
        expireDate: helper.getTokenExpirationDate(token)
      };
    } catch (e) {
      return null;
    }
  }

  setRefToken(refToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refToken);
  }

  getRefToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  removeRefToken(): void {
    localStorage.removeItem(REFRESH_TOKEN);
  }
}
