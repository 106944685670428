import {Component, OnInit} from '@angular/core';
import {AutoLogin} from './store/actions/authentication.actions';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private readonly _store: Store,
  ) {
  }

  ngOnInit(): void {
    this._store.dispatch(new AutoLogin());
  }
}
