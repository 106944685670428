import {Injectable} from '@angular/core';
import {
  Router, UrlTree, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {AutoLogin} from '../store/actions/authentication.actions';
import {AuthenticationState} from '../store/states/authentication.state';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private readonly _store: Store, private readonly _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._store.selectSnapshot(AuthenticationState.isLoggedIn)) {
      return true;
    }
    return this._router.createUrlTree(['/', 'auth']);
  }
}
