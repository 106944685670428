import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {TokenService} from '../services/token.service';
import {Logout} from '../store/actions/authentication.actions';

@Injectable({
  providedIn: 'platform'
})
export class UnauthorisedInterceptor implements HttpInterceptor {
  constructor(private store: Store, private tokenService: TokenService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((err) => {
      if (err.status === 401) {
        this.store.dispatch(new Logout());
      }
      throw err;
    }));
  }
}
